@import "common";

.top-section {
  width: 100%;
  max-width: 1240px;
  margin-right: auto;
  margin-left: auto;
  padding: 5% 0;
  width: 100%;

  .hero-content {
    display: flex;
    align-items: center;
    @include mobile {
      flex-direction: column;
      align-items: initial;
    }

    .hero-block {
      -webkit-text-size-adjust: 100%;
      color: #333;
      font-size: 14px;
      line-height: 20px;
      font-family: Poppins, sans-serif;
      font-weight: 400;
      box-sizing: border-box;
      display: flex;
      width: 50%;
      min-height: 350px;
      align-self: auto;
      flex-direction: column;

      .property-managers-title {
        font-size: 50px;

        @include mobile {
          font-size: 36px;
        }
      }

      @include mobile {
        width: 100%;
      }

      .places-wrapper {
        background: white;
        padding: 10px;
        border: 1px solid #dedede;
        box-shadow: 0 3px 15px 2px rgb(0 0 0 / 10%);
        border-radius: 8px;

        @include mobile {
          padding: 8px;
        }

        .place-input-wrapper {
          display: flex;
          width: 100%;

          .places-auto-complete {
            width: 100%;

            input {
              height: 55px;
              max-height: 55px;
              line-height: 55px;
              border-color: transparent;

              &::placeholder {
                line-height: 55px;
              }

              &:focus {
                border-color: transparent;
                outline: none;
                box-shadow: none;
              }
            }
          }

          .place-submit-btn {
            // width: 60px;
            height: 50px;
            width: auto;
            padding: 5px 20px;
            border-radius: 7px;

            @include mobile {
              padding: 5px 10px;
            }
          }
        }
      }
    }

    .hero-image-wrap {
      -webkit-text-size-adjust: 100%;
      color: #333;
      font-size: 14px;
      line-height: 20px;
      font-family: Poppins, sans-serif;
      font-weight: 400;
      box-sizing: border-box;
      position: relative;
      display: block;
      width: 50%;
      min-height: 350px;
      padding: 30px 30px 30px 0px;
      align-items: center;
      flex: 0 auto;
      margin-left: 20px;
      padding-right: 0px;
      padding-left: 0px;
      justify-content: flex-start;
      @include mobile {
        display: none;
      }

      img {
        -webkit-text-size-adjust: 100%;
        color: #333;
        font-size: 14px;
        line-height: 20px;
        font-family: Poppins, sans-serif;
        font-weight: 400;
        box-sizing: border-box;
        border: 0;
        vertical-align: middle;
        display: block;
        max-height: none;
        margin-left: 40px;
        max-width: 700px;
      }
    }
  }


  .bg {
    position: absolute;
    left: -70.6%;
    right: 36.31%;
    top: 1.3%;
    bottom: -5.5%;
    @include mobile {
      position: fixed;
      z-index: -1;
      
    }

    .bg-inner {
      position: absolute;
      left: -3.45%;
      right: 32.77%;
      top: 1.3%;
      bottom: -7.95%;

      background: linear-gradient(143.37deg, #EBF0FA 5.16%, rgba(235, 240, 250, 0) 73.02%);
      opacity: 0.5;
      transform: matrix(0.45, 0.31, -0.95, 0.51, 0, 0);
      @include mobile {
        right: 5%;
        bottom: -66%;
        display: none;
      }
    }
  }
}