@import 'common';

.agent-landing-page {
  @include mobile {
    padding: 100px 15px;
  }

  .buybox {
    margin-top: 45px;
    .items {
      padding-top: 25px;
      display: flex;
      justify-content: center;
      font-size: 16px;
      @include mobile {
        flex-direction: column;
      }
      > div > div {
        margin: 0 20px 10px;
        display: flex;
        align-items: center;
        svg {
          margin-right: 15px;
          font-size: 20px;
        }
      }
    }
  }

  .mid-section {
    @include desktop {
      display: flex;
      justify-content: space-between;
      margin-top: 80px;      
    }
    > .right {
      @include desktop {
        width: 55%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
    .mid-section-item {
        -webkit-text-size-adjust: 100%;
        font-size: 14px;
        line-height: 20px;
        font-family: Poppins, sans-serif;
        box-sizing: border-box;
        font-weight: 600;
        max-width: 100%;
        display: inline-block;
        position: relative;
        width: 100%;
        margin-bottom: 35px;
        padding: 25px;
        border-radius: 15px;
        background-color: #fff;
        box-shadow: 7px 7px 55px 0 #f2f3f6;
        color: #44465f;
        text-decoration: none;
        min-height: 150px;
        padding-top: 25px;
        padding-right: 10px;
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
        &:first-child {
          @include desktop {
            margin-top: 0;
          margin-bottom: 90px;
          }
        }
        &:nth-child(3) {
          @include desktop {
            margin-top: -60px;
          margin-bottom: 70px;
          }
        }

        @include desktop {
          max-width: 312px;
          margin-left: 35px;
        }
      }
    }
    > .left {
      @include desktop {
        width: 45%;
        @include desktop {
          margin-top: 100px;
        }
      }
      h3 {
        text-align: left;
        margin: 0 0 25px; padding: 0;
      }
    }


  }

  .cta-btn {
    max-width: 200px;
  }
  .bottom-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      margin: 0 0 50px;
      padding: 0 !important;
      max-width: initial;
      text-align: center;
      font-weight: normal;
      max-width: 800px;
      font-size: 32px;
      line-height: 40px;
      @include mobile {
        font-size: 25px;
        line-height: 32px;
      }
    
    }
    .cta-btn-bottom {
      max-width: 300px;
      width: 100%;
    }
  }

  .how-it-works {
    img {
      height: fit-content !important;
    }
  }

  .contact {
    display: flex;
    justify-content: center;
    margin: 100px 0;

    p {
      @include mobile {
        text-align: center;
      }
    }

    a {
      color: #353760;
      @include mobile {
        font-size: 17px;
      }
      @include desktop {
        white-space: nowrap;
      }
      &:hover {
         text-decoration: underline;
      }
    }
  }
}
