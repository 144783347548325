@import "common";

.how-it-works {
    background: $PALETTE_THIRD;
    margin-top: 180px;
    @include mobile {
      margin-top: 70px;
    }

      .section-inner {
        
        margin: 0 auto;
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
        @include mobile {
          max-width: 100%;
          padding: 0 10px;
          flex-direction: column;
        }
        @include desktop {
          max-width: 1280px;
        }
      }


    h3 {
      margin-top: -80px;
      @include mobile {
        text-align: center;
      }
      @include desktop {
        text-align: right;
        padding-left: 36%;
        padding-top: 80px;
        padding-bottom: 80px;
        margin-top: -160px;
      }
    }
    > .section-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include desktop {
        padding-bottom: 100px;
      }
      @include mobile {
        padding-bottom: 60px;
      }

      .items {
        display: flex;
        justify-content: center;
        width: 100%;

        > .right.steps {
          display: flex;
          flex-direction: column;
          @include mobile {
            padding-top: 60px;
            padding-left: 10px;
            padding-right: 10px;
          }
          @include desktop {
            width: 45%;
            padding-left: 100px;
          }
          .step-item {
            display: flex;
            margin-bottom: 35px;
            @include mobile {
              margin-bottom: 50px;
            }
            .number-wrap {
              width: 60px;
              height: 60px;
              min-width: 60px;
              min-height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #dee2e0;
              text-align: center;
              border-radius: 25px;
              font-size: 20px;
              color: #333;
              min-width: 60px;
              border-radius: 67px;
              margin-top: -16px;
              background: #333;
              color: white;
              @include mobile {
                width: 45px;
                height: 45px;
                min-height: 45px;
                min-width: 45px;
              }
            }
            > .content {
              
              @include mobile {
                margin-top: -10px;
                padding-left: 20px;
              }
              @include desktop {
                padding-left: 35px;
              }
              > .title {
                color: rgb(22, 22, 22);
                font-size: 18px;
                font-weight: 600;
                padding-bottom: 15px;
              }
              > .desc {
                font-size: 15px;
              }
            }
          }
        }
        > .left {
          display: flex;
          justify-content: flex-end;
          width: 50%;
          position: relative;

          @include mobile {
            display: none;
          }
          
          .left-bg {
            position: absolute;
            width: 90%;
            height: auto;
            z-index: 0;
            top: -50px;
            left: 0;
            @include mobile {
              display: none;
            }
          }

          img {
            z-index: 1;
            width: 280px;
            height: auto;
            margin-top: -120px;
            @include desktop {
              // margin-top: -100px;
              margin-right: -40px;
            }
          }
        }
      }
      .bottom-part {
        margin: 80px auto 0;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        @include mobile {
          margin-top: 0;
        }
        h4 {
          font-size: 22px;
          padding-bottom: 15px;
          text-align: center;
        }
        .places-wrapper {
          width: 100%;
          max-width: 500px;
          
        }
      }
    }

    .places-wrapper {
        background: white;
        padding: 10px;
        border: 1px solid #DEDEDE;
        box-shadow: 0 3px 15px 2px rgb(0 0 0 / 10%);
        border-radius: 8px;     
        @include mobile {
          padding: 8px;
        }       
        .place-input-wrapper {
          display: flex;
          width: 100%;
          .places-auto-complete {
            width: 100%;
    
            input {
              height: 55px;
              max-height: 55px;
              line-height: 55px;
              border-color: transparent;
    
              &::placeholder {
                line-height: 55px;
    
              }
              &:focus {
                border-color: transparent;
                outline: none;
                box-shadow: none;
              }
            }
          }
          .place-submit-btn {
            // width: 60px;
            height: 50px;
            width: auto;
            padding: 5px 20px;
            border-radius: 7px;
            @include mobile {
              padding: 5px 10px;
            }
          }
        }
      }

  }